

import { types } from '../types/types';
import { startResetPage } from './sales';


export const changeFilter = (filter) => {
    return async(dispatch) => {
        localStorage.setItem('currenFilter', JSON.stringify(filter ?? []));
        await dispatch(changeFilterAction(filter));
        dispatch(startResetPage());
    }
}

const changeFilterAction = ( filter ) => ({
    type: types.changeFilter,
    payload: {currentFilter: filter}
});
